
.p-dialog-mask {
  pointer-events: all !important;
}

html {
  min-height: 100vh;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif, “Apple Color Emoji”, “Segoe UI Emoji”, “Segoe UI Symbol”;
  min-height: 100vh;
  margin: 0;
}
.p-button-warning {
  color: white !important;
}

.modal {

  max-width: 768px;

  &.modal-error {

    &.p-dialog {

      .p-dialog-title,
      .p-dialog-content {

        color: #ff0000;

      }

    }

  }

  &.modal-input {

    width: 80%;

  }

  &.modal-signature {

    max-width: 100%;
    width: auto;

    overflow: hidden;

    &.p-dialog {

      .p-dialog-content {

        padding: 1.5rem;

      }

    }

  }

  &.modal-contact-list {

    width: 100%;
    height: 100%;
    max-height: 90%;

  }

  // &.modal-generic {

  //   &.p-dialog {

  //     .p-dialog-title,
  //     .p-dialog-content {

        

  //     }

  //   }

  // }

  &.overflow-visible {

    &.p-dialog {

      .p-dialog-content {

        overflow-y: visible;

      }
      
    }

  }

}

.card-note {

  .p-card {

    background: repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.025),
      rgba(0, 0, 0, 0.025) 10px,
      rgba(0, 0, 0, 0.050) 10px,
      rgba(0, 0, 0, 0.050) 20px
    );

    border: 2px solid rgba(0, 0, 0, 0.075);

    color: rgb(59, 59, 69);

  }

}

.card-error {

  .p-card {

    background: repeating-linear-gradient(
      45deg,
      rgba(197, 0, 0, 0.0625),
      rgba(197, 0, 0, 0.0625) 10px,
      rgba(197, 0, 0, 0.125) 10px,
      rgba(197, 0, 0, 0.125) 20px
    );

    border: 2px solid rgba(197, 0, 0, 0.5);

    color: rgb(99, 0, 0);

  }

}

.card-success {

  .p-card {

    background: repeating-linear-gradient(
      45deg,
      rgba(0, 197, 101, 0.0625),
      rgba(0, 197, 101, 0.0625) 10px,
      rgba(0, 197, 101, 0.125) 10px,
      rgba(0, 197, 101, 0.125) 20px
    );

    border: 2px solid rgba(0, 197, 101, 0.5);

    color: rgb(0, 99, 50);

    width: 100%;
    text-align: center;
    box-shadow: none;

  }

}

.card-warning {

  .p-card {

    background: repeating-linear-gradient(
      45deg,
      rgba(255, 175, 0, 0.125),
      rgba(255, 175, 0, 0.125) 10px,
      rgba(255, 175, 0, 0.25) 10px,
      rgba(255, 175, 0, 0.25) 20px
    );

    border: 2px solid rgba(255, 175, 0, 0.5);

    color: rgb(125, 98, 0);

    width: 100%;
    text-align: center;
    box-shadow: none;

  }

}

.card-info {

  .p-card {

    background: repeating-linear-gradient(
      45deg,
      rgba(0, 175, 255, 0.125),
      rgba(0, 175, 255, 0.125) 10px,
      rgba(0, 175, 255, 0.25) 10px,
      rgba(0, 175, 255, 0.25) 20px
    );

    border: 2px solid rgba(0, 175, 255, 0.5);

    color: rgb(0, 87, 125);

  }

}

.card-help {

  .p-card {

    background: repeating-linear-gradient(
      45deg,
      rgba(255, 0, 247, 0.125),
      rgba(255, 0, 247, 0.125) 10px,
      rgba(255, 0, 247, 0.25) 10px,
      rgba(255, 0, 247, 0.25) 20px
    );

    border: 2px solid rgba(255, 0, 247, 0.5);

    color: rgb(110, 0, 125);

  }

}

p-card {
  
  .p-card {

    width: 100%;
    text-align: center;
    box-shadow: none;

    .p-card-title {
      font-size: 16px;
    }

    .p-card-body {

      .p-card-content {
    
        padding: 0;
    
        p {
          
          text-align: left;
          margin: 0;

        }
        
      }

    }

  }

}

.button-actions {

  display: flex;
  justify-content: flex-end;

  button {

    margin-left: 1em;

    &:first {

      margin-left: 0;

    }

  }
}

.pac-container {
  z-index: 9999999999 !important;
}

.required {
  &::after {
    content: " ***";
    color: orange;
    font-size: 20px !important;
    font-weight: bold;
    line-height: 0;
  }
}

.dynamic-required {
  &::after {
    content: " *";
    color: #ff0000;
    font-size: 20px !important;
    font-weight: bold;
    line-height: 0;
  }
}

input, textarea {

  &:read-only {
    
    pointer-events: none;

    background-color: rgba(0,0,0,0.05);
    
  }

}

p-inputswitch {

  &.is-read-only {

    opacity: 0.5;
    
  }

}

.keep-line-breaks {
  white-space: break-spaces;
}

.sticky-submit-button-container {
  position: sticky;
  right: -4px;
  bottom: -2px;
  border-top: 1px solid var(--surface-border);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  z-index: 2;
  text-align: right;
  background-color: #fff;
  box-shadow: 2px 0px 1px #fff;
}

 
.p-inputswitch {
  .p-inputswitch-slider {
    background: red !important;
    &::after{
      content: "NO";
      color: #fff;
      position: absolute;
      top: 50%;
      right: 4px;
      transform: translateY(-50%);
      font-size: 10px;
    }
  }    
}

.p-inputswitch-checked {
  .p-inputswitch-slider {
    background: green !important;
    &::after{
      content: "YES";
      color: #fff;
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      font-size: 10px;
    }
  }
}

label.with-sub-label {
  position: relative;
  span.sub-label {
    position: absolute;
    top: 50%;
    right: 0;
    padding-right: 1em;
    opacity: 0.5;
    transform: translateY(-50%);
    margin-top: -3px;
  }
}